/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { Alert } from '@jagex-pp/jds';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Request } from '@common/requests';
import { Loader } from '@components/Loader';
import { setTargetRoute } from '@utils/navigation';

export const RedirectRoute: FunctionComponent = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [isWithError, setIsWithError] = useState(false);

  useEffect(() => {
    setTargetRoute(location);

    Request.getLoginLink()
      .then(({ loginLink }) => window.location.assign(loginLink))
      .catch(() => setIsWithError(true));
  }, [location]);

  return isWithError ? <Alert level="error">{t('errorPage:commonNotification')}</Alert> : <Loader />;
};
