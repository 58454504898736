/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import AdyenCheckout from '@adyen/adyen-web';
import { AdyenInstance, PaymentConfig } from './types';

export const adyenInit = (config: PaymentConfig): Promise<AdyenInstance[]> => {
  const onAction = (res) => {
    if (res.error === 'invalidOriginError' || Error(res).message === 'ERROR: secured fields have failed to configure') {
      return;
    }
    window.location.href = `${config.returnUrl}${res?.resultCode ? `&resultCode=${res.resultCode}` : ''}`;
  };

  return AdyenCheckout(config.adyen).then((res) => {
    const processedConfigs: AdyenInstance[] = [];

    res.paymentMethodsResponse.paymentMethods
      .filter((method) => !['scheme', 'card'].includes(method.type))
      .forEach((method) => {
        const methodData = {
          ...res,
          ...config,
          paymentMethodsResponse: {
            paymentMethods: [method],
          },
          onPaymentCompleted: onAction,
          onError: onAction,
        };

        processedConfigs.push(methodData);
      });

    if (config.checkout) {
      processedConfigs.unshift({
        paymentMethodsResponse: { paymentMethods: [{ type: 'card', name: 'Card payment' }] },
      });
    }

    return processedConfigs;
  });
};
