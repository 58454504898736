/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { Global, css, SerializedStyles } from '@emotion/react';
import { sizes, device, typography } from '@jagex-pp/jds';
import React, { FunctionComponent } from 'react';

const GlobalStyle: SerializedStyles = css(`
  #root, html, body {
    height: 100%;
  }
  #checkoutIframe {
    width: 100%;
    border: 0;
  }
  nav {
    flex-shrink: 0;
  }
  h1 {
    margin: 0 !important;
    font-size: ${typography.sizes['32']} !important;

    @media screen and (${device.xsmall}) {
      margin: 0 0 ${sizes.s56.px} !important;
      font-size: ${typography.sizes['48']} !important;
    }
  }
  details[open] div {
    overflow: inherit;
  }
`);

export const GlobalStyles: FunctionComponent = () => <Global styles={GlobalStyle} />;
