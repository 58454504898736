/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { injectable } from 'inversify';
import { makeObservable, observable, action, computed } from 'mobx';
import { I18n } from '@i18n/i18n';

@injectable()
export class Localisation {
  localisationInstance: I18n | null = null;

  constructor() {
    makeObservable(this, {
      localisationInstance: observable,
      setLocalisationInstance: action,
      locale: computed,
    });
  }

  setLocalisationInstance(localisationInstance: I18n): void {
    this.localisationInstance = localisationInstance;
  }

  get locale(): string | undefined {
    return this.localisationInstance?.i18nInstance.language;
  }
}
