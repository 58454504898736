/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { JdsProvider } from '@jagex-pp/jds';
import React, { FunctionComponent } from 'react';
import { GlobalStyles } from '@common/Global';
import { I18n } from '@i18n/i18n';
import { Store } from '@stores/createStore';
import { StoreProvider } from '@stores/Provider';
import { AppRouter } from './AppRouter';

interface Props {
  i18nInstance: I18n;
  store: Store;
}

const App: FunctionComponent<Props> = ({ store, i18nInstance }) => (
  <StoreProvider store={store}>
    <JdsProvider>
      <AppRouter i18nInstance={i18nInstance} />
      <GlobalStyles />
    </JdsProvider>
  </StoreProvider>
);

export default App;
