/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { ParsedUrlQuery } from 'querystring';
import { injectable } from 'inversify';
import { action, computed, observable, makeObservable } from 'mobx';
import { StatusCode } from '@common/constants';
import { Requests } from '@stores/domains/Auth/Requests';
import { checkErrorStatusCode } from '@utils/network';
import { AuthStatus, LogoutResponse, LoginLinkRequest } from './types';

@injectable()
export class Auth {
  authStatus: AuthStatus = AuthStatus.UNKNOWN;

  constructor() {
    makeObservable(this, {
      authStatus: observable,
      isAuthenticated: computed,
      setAuthState: action,
    });
  }

  setAuthState(state: AuthStatus): void {
    this.authStatus = state;
  }

  get isAuthenticated(): boolean {
    return this.authStatus === AuthStatus.AUTHORIZED;
  }

  async checkAuthStatus(): Promise<void> {
    try {
      await Requests.checkAuthStatus();
      this.setAuthState(AuthStatus.AUTHORIZED);
    } catch (err) {
      const isUnauthorized = checkErrorStatusCode(StatusCode.Unauthorized, err);

      if (isUnauthorized) {
        this.setAuthState(AuthStatus.UNAUTHORIZED);
      } else {
        return Promise.reject(err);
      }
    }
  }

  async getOAuthTokens(queryParams: ParsedUrlQuery): Promise<void> {
    return Requests.getOAuthTokens(queryParams);
  }

  async getLoginLink(locale: string): Promise<LoginLinkRequest> {
    const params = { locale, client: 'checkout' };

    return Requests.getLoginLink({ params });
  }

  async logout(): Promise<LogoutResponse> {
    return await Requests.logout();
  }
}
