/*
 * Copyright 1999-2024 Jagex Ltd.
 */
export enum AuthStatus {
  UNKNOWN,
  AUTHORIZED,
  UNAUTHORIZED,
  FORBIDDEN,
}

export const StatusToAuthStatus = {
  '401': AuthStatus.UNAUTHORIZED,
  '403': AuthStatus.FORBIDDEN,
};

export interface LoginLinkRequest {
  loginLink: string;
}

export type LogoutResponse = {
  redirectURL: string;
};
