/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { AxiosRequestConfig } from 'axios';
import { api, extractResponse } from '@utils/network';
import { GetOrderDataRequest, OrderData, CreateSkuRequest, CreateSkuResponse } from './types';

export const Requests = {
  getOrderData: (skuKey: string, params: GetOrderDataRequest, config: AxiosRequestConfig = {}): Promise<OrderData> =>
    extractResponse<OrderData>(api.get(`/skuOrders/${skuKey}`, { ...config, params: { ...params } })),
  createSkuOrder: async (body: CreateSkuRequest, config: AxiosRequestConfig = {}): Promise<CreateSkuResponse> =>
    extractResponse<CreateSkuResponse>(api.post('/skuOrders', body, config)),
};
